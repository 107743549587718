

import React from 'react'
import SeoPage from '../components/SeoPage'
import UpperForm from '../components/UpperForm'
import BottomForm from '../components/BottomForm'
import { StaticImage } from 'gatsby-plugin-image'
import LeadImage from '../images/garanties-achat-maison-avocat.jpg'
import { graphql } from 'gatsby'
import ConversionLink from '../components/ConversionLink'


const BlogPost17 = ({ data, location, ...props }) => (
    <SeoPage location={location}
        title="Toutes les garanties qui viennent avec l’achat d’une maison : à quoi s’engage le vendeur lorsqu’il vous vend sa maison? - Soumissions Avocat"
        description="Votre nouvelle maison est atteinte d’un vice de qualité? Voici toutes les garanties à faire valoir contre le vendeur avec l’aide d’un avocat!"
        image={LeadImage}>
        <h1>Toutes les garanties qui viennent avec l’achat d’une maison : à quoi s’engage le vendeur lorsqu’il vous vend sa maison?</h1>

					
					
						<p><strong>Vous êtes récemment devenu propriétaire d’une maison?</strong> Toutes nos félicitations pour votre statut de propriétaire immobilier! Avec un tel statut viennent évidemment de nouvelles obligations, mais également des droits, dont ceux de garantie prévus par la loi. Notamment, la garantie légale de qualité et la garantie du droit de propriété jouent désormais en votre faveur, mais connaissez-vous réellement leur étendue?</p>
<p><StaticImage alt="garanties achat maison avocat" src="../images/garanties-achat-maison-avocat.jpg" placeholder="blurred" imgStyle={{ maxWidth: '80%', margin: '0 auto' }}></StaticImage></p>
<p>Bien des propriétaires de maison ignorent complètement l’ampleur de leurs recours lorsqu’un problème survient! En effet, que faire lorsque le toit se met à couler peu de temps après l’achat de la maison? Vers qui vous tourner lorsque vous constatez un empiètement sur votre terrain? Pire encore, que faire lorsque vous réalisez qu’une partie de votre propriété a été prescrite au bénéfice d’un de vos nouveaux voisins?</p>
<p><strong>La réponse : vous vous tournez vers un avocat spécialisé en droit immobilier et en contrats de vente! Ce professionnel vous expliquera l’étendue des garanties que vous offre la loi québécoise en tant qu’acheteur immobilier! </strong></p>
<h2>La garantie du droit de propriété, une première protection essentielle!</h2>
<p>On pense souvent que la garantie légale de qualité est la seule qui importe en matière immobilière, mais détrompez-vous, car la garantie du droit de propriété constitue la fondation de vos droits! En effet, celle-ci vise à assurer que les titres de propriété soient transmis de façon conforme d’un propriétaire à un autre, formant ainsi une chaîne de titres dénuée de vices.</p>
<p><strong>Mais qu’arrive-t-il lorsqu’un acheteur constate un problème au niveau de cette même garantie? Voici ce que vous devez savoir!</strong></p>
<p><strong>Qu’est-ce qui est inclus dans la garantie du droit de propriété? </strong>De prime abord, cette garantie impose une obligation au vendeur d’assurer à l’acheteur que le titre qui lui est transmis soit clair, lui garantissant ainsi une possession de la maison paisible, utile et dénuée de trouble. Ce faisant, le vendeur est tenu responsable si ces troubles émanent des tiers ou de sa propre faute, ce qui justifiera un recours de l’acheteur.</p>
<p>Les hypothèques précédentes ont été purgées. Les propriétés immobilières possèdent la particularité d’être grevées d’hypothèques. Qu’il s’agisse d’hypothèques conventionnelles ou d’hypothèques légales de la construction, le vendeur est tenu non seulement de déclarer ces mêmes charges, mais également de les purger avant la réalisation de la vente, ce qui implique de les faire radier.</p>
<p><strong>Qu’arrive-t-il si vous avez acheté une maison grevée d’une hypothèque?</strong> Vous serez alors justifié de demander la nullité de la vente ou d’exiger du vendeur qu’il assume tous les frais engendrés par cette situation. Ainsi, la nullité de la vente d’une maison  hypothéquée devient possible en raison du fait que le vendeur manque à son obligation de délivrance de la maison « libre de toutes charges ».</p>
<p>L’absence d’empiètement doit être garantie par le vendeur. Au sens de la loi, un empiètement constitue une atteinte au droit de propriété causé par un tiers lorsque ce dernier occupe un espace d’un terrain voisin excédant sa propre propriété. Par exemple, une clôture construite par le voisin sur votre fonds ou un garage construit en partie dans votre cour constituent tous deux des empiètements que le vendeur est tenu de vous dénoncer avant de transférer la propriété.</p>
<p>L’absence violation de limitations d’ordre public, sauf celles dénoncées par le vendeur. Que signifie cette obligation quelque peu complexe? En fait, une limitation d’ordre public prend la forme d’une loi ou d’un règlement restreignant l’utilisation de votre propriété. Prenons, par exemple, un règlement de zonage agricole qui empêcherait un certain type de construction dans un rayon déterminé ou encore un règlement municipal interdisant la construction de bâtiments commerciaux dans ce même rayon.</p>
<p>Pareilles lois peuvent compromettre la réalisation des projets qu’un acheteur aurait souhaité réaliser, alors <strong>quelles sont les obligations du vendeur à son égard?</strong> En vertu de la loi et de la garantie du droit de propriété, le vendeur n’est pas tenu de divulguer les limitations d’ordre public; il est seulement tenu de garantir à l’acheteur que la propriété qu’il souhaite acquérir ne viole aucune de ces limitations au moment de la vente.</p>
<p>Par exemple, le vendeur d’un logement abritant un local commercial au rez-de-chaussée en violation des règlements municipaux se devrait d’en informer l’acheteur. À l’inverse, advenant que la présence d’un tel commerce respecte la loi, le vendeur ne serait pas tenu de divulguer à l’acheteur toutes les limitations qu’impose la loi à l’égard de celui-ci; l’acheteur doit effectuer ses propres vérifications diligentes pour le reste.</p>
<h2>Garantie légale de qualité, est-ce une couverture contre tous les vices?</h2>
<p>Maintenant que la garantie du droit de propriété n’a plus de secrets pour vous, parlons du nerf de la guerre, source d’innombrables litiges, soit la garantie légale de qualité! Représentant aussi bien un droit conféré à l’acheteur qu’une obligation imposée au vendeur, pareille garantie est d’une importance primordiale lors d’une transaction immobilière, à condition d’en comprendre le fonctionnement!</p>
<p><StaticImage alt="garantie legale qualite vice" src="../images/garantie-legale-qualite-vice-scaled.jpg" placeholder="blurred" imgStyle={{ maxWidth: '80%', margin: '0 auto' }}></StaticImage></p>
<p><strong>La garantie légale de qualité </strong>consiste en l’obligation du vendeur de fournir un bien pouvant servir à l’usage aux fins pour lesquels il a été acheté en fonction de sa nature, tout en incluant ses accessoires. Dans les faits, cette définition vise non seulement les vices cachés à l’acheteur, mais également les représentations faites par le vendeur concernant des qualités que la maison ne possède pas.</p>
<p>Vous aurez donc compris que la garantie légale de qualité est la source des litiges pour <strong>vices cachés!</strong> En effet, comme le vendeur remet un bien dont la valeur s’en retrouve diminuée ou dont la qualité s’en retrouve amputée, l’acheteur est lésé et conséquemment justifié de demander réparation.</p>
<p><strong>Le vendeur n’est toutefois pas responsable des vices que l’acheteur prudent aurait pu découvrir lui-même l’existence! </strong>Certes, la garantie de qualité confère plusieurs droits à l’acheteur, mais encore faut-il que celui-ci se soit comporté comme une personne prudente! À ce propos, <strong>l’acheteur imprudent</strong> n’est pas protégé par la garantie légale de qualité, puisque la loi ne considère pas comme cachés les vices qu’un acheteur prudent aurait pu déceler par un examen ordinaire lors de l’achat.</p>
<p>Il s’agit là d’une excellente raison de faire inspecter la maison par un professionnel avant de conclure la transaction, puisque le rôle de l’inspecteur est précisément de déceler les vices apparents qu’un acheteur profane ne remarquerait pas.
<strong>
</strong>
<strong>Est-il nécessaire de faire appel à un expert pour être un acheteur « prudent »? </strong>Pas nécessairement, mais cela est fortement conseillé! À vrai dire, la loi ne vous empêche pas automatiquement d’intenter un recours si vous avez omis d’engager un expert comme un inspecteur en bâtiment. Cependant, tout est affaire de circonstances, puisqu’on se réfèrera au standard de l’acheteur prudent placé dans la même situation. <strong>
</strong>
<strong>Par exemple, prenons le cas de l’achat d’une maison vétuste ou ancestrale! </strong>Pareille maison possède bien souvent une plomberie âgée, une toiture usée, un plancher en fin de vie et d’autres composantes affectées par le temps. Un acheteur prudent, dans les circonstances d’un tel achat, commettrait une imprudence en ne poussant pas l’inspection de la maison plus loin que son simple examen visuel. Le recours à l’expert pourrait donc s’avérer fort utile lors d’une telle transaction.</p>
<p><strong>Que faire si vous croyez que le vendeur n’a pas respecté son obligation de garantie légale de qualité?</strong> Le recours pour vice caché demeurera évidemment ouvert, à condition que tous les critères soient présents et que vous agissiez en temps utile!</p>
<h2>Quelles sont les limites d’une vente de maison aux risques et périls?</h2>
<p>La garantie légale de qualité, telle qu’expliquée ci-haut, peut être écartée par le vendeur et l’acheteur. Effectivement, il s’agit-là de la fameuse « vente sans garantie légale », aussi appelée la vente « aux risques et périls de l’acheteur ». Vous n’êtes surement pas sans savoir qu’une telle vente empêche d’intenter un recours contre le vendeur suite à la découverte d’un vice caché.</p>
<p><strong>Est-ce vrai pour autant que l’acheteur supporte tous les périls d’une telle transaction? Pas si vite, des limites sont tout de même prévues par la loi!</strong></p>
<p>D’abord, le vendeur doit tout de même <strong>dénoncer les vices qu’il connait</strong> à l’acheteur. En effet, la vente aux risques et périls vise à protéger le vendeur de bonne foi qui, reconnaissant le potentiel de défauts cachés, dénonce à l’acheteur une telle situation avant de passer titre. À l’inverse, une vente excluant la garantie légale ne vise pas à permettre au vendeur de « passer un citron » à l’acheteur.</p>
<p>En somme, un tel silence pourrait s’avérer <strong>un dol justifiant</strong> l’<strong>ouverture à un recours. </strong>En effet, un vendeur qui garde le silence, rassure, ou ment à l’acheteur commet une fraude envers ce dernier qui pourra justifier non seulement une diminution du prix de vente, mais l’octroi de dommages-intérêts également. Dans certaines situations graves, l’annulation de la transaction pourrait même être de mise.</p>
<p><strong>Un vice apparent peut-il devenir caché en raison du vendeur? </strong>Tout à fait! Lorsqu’un vendeur tente de réconforter un acheteur qui manifeste des inquiétudes ou des soupçons concernant la qualité du bien et que le vendeur tente de le rassurer en minimisant le vice constaté, il peut s’agir d’un <strong>vice juridiquement caché</strong>!</p>
<p>Un exemple fort simple serait le fait d’un acheteur potentiel constatant la présence d’eau sur le bord des fenêtres lors d’une visite préachat et qui en fait part au vendeur. Ce dernier, souhaitant conclure la transaction, lui dit qu’il a simplement oublié de refermer la fenêtre avant la dernière averse de pluie, même s’il sait pertinemment que les fenêtres de l’immeuble ne sont plus étanches en raison de leur âge.</p>
<p>En réconfortant son acheteur, celui-ci transforme un vice apparent (fenêtres qui coulent) en un vice juridiquement caché par le fait de ses mensonges. Encore une fois, le recours en diminution de prix, l’annulation de la vente ou l’octroi de dommages-intérêts s’offriront à l’acheteur selon les circonstances.</p>
<h2>Vente immobilière sans égard à la contenance, ce que vous devez savoir!</h2>
<p><strong>Le vendeur n’en a pas encore fini avec les obligations!</strong> En effet, la loi lui impose le devoir de délivrer le bien à l’acheteur en respectant la quantité et la contenance stipulée au contrat. Certainement, cela ne doit pas causer problème en matière de vente de maison vous direz, n’est-ce pas? Erreur! Voici comment les problèmes de contenance surviennent en matière immobilière.</p>
<p><StaticImage alt="vente terrain sans contenance avocat" src="../images/vente-terrain-sans-contenance-avocat-scaled.jpg" placeholder="blurred" imgStyle={{ maxWidth: '80%', margin: '0 auto' }}></StaticImage></p>
<p><strong>Qu’est-ce que la contenance à l’occasion d’un contrat de vente? </strong>Il s’agit d’une des composantes de l’obligation de délivrance forçant le vendeur à remettre la quantité déterminée. En matière de vente immobilière, ce problème survient surtout lorsque la superficie du terrain est en cause.</p>
<p>Par exemple, le vendeur d’un terrain en considération d’une superficie de 10 000 pieds carrés ne respecte pas son obligation de délivrance si la dimension réelle est de 9 000 pieds carrés. Il demeure cependant possible pour un acheteur de conclure un contrat sans égard à la contenance!</p>
<p><strong>Exclusion de la contenance, quel impact sur les droits de l’acheteur? </strong>Pour qu’une telle vente soit valide, la stipulation à l’effet de la contenance doit être claire dans le contrat. Dans le cas contraire, le vendeur pourra toutefois affirmer que les parties avaient convenu d’exclure la contenance, mais il aura un lourd fardeau de preuve à surmonter pour faire cette preuve, puisqu’un acheteur est présumé acheter en fonction de la contenance.</p>
<h2>Quels sont vos recours contre le vendeur si vous découvrez un vice ou une malfaçon?</h2>
<p>Advenant que vous réussissiez à faire la preuve d’un vice caché ou d’une atteinte à votre droit de propriété engageant la responsabilité du vendeur, trois types de dédommagements s’offriront à vous. Est-ce que le choix vous revient ou s’agit-il plutôt d’une option dictée par les circonstances?</p>
<p><strong>L’annulation de la transaction, </strong>bien que souvent désirée par les acheteurs lésés par un vice caché, est en réalité un recours exceptionnel qui ne sera justifié que lorsque le défaut caché revêt d’une certaine gravité. En effet, pareil acheteur devra faire la preuve que s’il avait connu le vice, il n’aurait pas acheté la propriété. Qui plus est, le recours est bien souvent impraticable en raison du fait que le vendeur aura déjà acheté une nouvelle propriété.</p>
<p>De ce fait, même si l’acheteur obtient un jugement ordonnant l’annulation de la vente, il y a fort à parier que le vendeur ne sera pas assez solvable pour le rembourser en raison de ses nouveaux engagements financiers, justifiant ainsi un recours différent dans bien des circonstances.<strong>
</strong>
<strong>La diminution du prix de vente </strong>est donc l’option la plus appropriée dans la majorité des circonstances, d’autant plus que le calcul de l’indemnité se fait aisément. À raison, l’acheteur intentant un tel recours devra faire la preuve que s’il avait connu le vice, il aurait acheté à un prix moindre, tout en faisant ensuite la preuve de la diminution de valeur engendrée par le vice découvert. De plus, l’acheteur sera justifié de réclamer les frais engendrés pour procéder aux réparations urgentes. <strong>
</strong>
<strong>Le recours en dommages-intérêts </strong>peut quant à lui être intenté de façon accessoire aux deux recours lorsque le vendeur a arboré une conduite fautive. Par exemple, un recours en dommages-intérêts pourrait accompagner l’action en diminution du prix de vente si le vendeur a commis de fausses représentations à l’acheteur.</p>
<p><strong>Comment choisir le recours approprié à votre situation?</strong> Consulter un avocat d’expérience dans le domaine serait une première étape fort utile, alors faites confiance à Soumissions Avocat pour dénicher ce professionnel dans votre région!</p>
<h2>Acheteur d’une maison neuve ou en construction : des recours particuliers!</h2>
<p>Après des années de sacrifices, vous êtes enfin parvenus à acheter une maison flambant neuve ou, mieux encore à vous faire bâtir la maison de vos rêves? Saviez-vous que des lois, garanties et protections spéciales s’appliquent à de tels ouvrages immobiliers? En effet, en plus de la garantie légale de qualité et du droit de propriété, le Code civil vous octroie des <strong>garanties additionnelles</strong>!</p>
<p><StaticImage alt="garantie maison neuve cinq ans" src="../images/garantie-maison-neuve-cinq-ans-scaled.jpg" placeholder="blurred" imgStyle={{ maxWidth: '80%', margin: '0 auto' }}></StaticImage></p>
<p>Tout d’abord, l’article 2120 du Code civil prévoit un<strong> recours de 1 an </strong>contre les entrepreneurs, architectes et ingénieurs ayant supervisé les travaux pour les malfaçons constatées. Ainsi, dans l’année suivant la construction de votre maison, vous n’avez qu’à démontrer la présence d’une malfaçon afin que votre entrepreneur ne soit tenu de la corriger.</p>
<p><strong>Faites attention de ne pas accepter les travaux sans réserve! </strong>À la fin des travaux, une inspection serait faite avec l’entrepreneur afin que vous ayez la chance de constater leur état et leur qualité. À ce moment, vous aurez le choix d’accepter les travaux sans réserve, indiquant que tout est à votre satisfaction, ou encore d’accepter avec réserve, indiquant ainsi que vous avez constaté un vice apparent nécessitant des modifications.</p>
<p>Le fait d’accepter les travaux sans réserve vous fait perdre tout recours pour les vices qui étaient apparents, tout en vous laissant le droit d’intenter un recours pour les vices qui étaient cachés. Ne soyez donc pas trop pressés d’accepter les travaux sans réserve! <strong>
</strong>
<strong>Un second recours encore plus important vous est également offert, soit celui de 5 ans contre les vices de construction. </strong>En fait, l’article 2118 C.c.Q. prévoit une présomption de responsabilité envers l’entrepreneur, l’architecte et l’ingénieur qui préparent les travaux! En vertu de ce même article, un vice est un défaut de construction qui met en péril la solidité de l’immeuble et qui fait craindre son écroulement ou son affaissement.</p>
<p>Cette disposition légale implique qu’un recours peut être intenté solidairement contre l’entrepreneur, l’architecte et l’ingénieur; il reviendra à ces derniers de prouver leur absence de faute pour exclure leur responsabilité, puisque l’article 2118 C.c.Q. crée une présomption en faveur du propriétaire, renversant ainsi le fardeau de preuve.</p>
<h2>Combien de temps avez-vous pour intenter un recours contre le vendeur?</h2>
<p>Concernant le <strong>recours contre les malfaçons</strong> résultant de la faute solidaire de l’entrepreneur, de l’architecte et des ingénieurs, le délai est de 1 an à partir de la réception de l’ouvrage complété. En ce qui a trait au <strong>recours contre les vices</strong>, le délai est plutôt porté à 5 ans à partir de la réception de l’ouvrage achevé.</p>
<p><strong>Êtes-vous sans recours si le délai de cinq ans est expiré? </strong>Advenant que vous dépassiez les délais de 1 an et de 5 ans mentionnés dans le Code civil, vous n’êtes pas sans recours pour autant, puisque le recours en responsabilité civile traditionnel demeurera ouvert. Celui-ci est toutefois assujetti à un délai de prescription de prescription de 3 ans.</p>
<p>Ainsi, le délai de prescription de droit de commun <strong>d’une durée de 3 ans</strong> s’applique tout de même aux deux recours précédents à partir de la connaissance, même si les délais de 1 an et de 5 ans sont expirés.</p>
<h2>Un problème avec votre nouvelle maison? Voici la marche à suivre!</h2>
<p>C’est confirmé, votre maison est affligée d’un <a href="https://jurigo.ca/avocat-vice-cache/">vice caché</a> ou d’un vice concernant votre droit de propriété? Il est maintenant temps d’agir, et ce, sans délai auprès d’un avocat qualifié droit immobilier et spécialisé en contrats de vente. Voici les grandes étapes de la démarche d’un recours contre un vendeur!</p>
<p><strong>La dénonciation rapide est essentielle en matière de vice caché!</strong></p>
<p>Évitez de faire des réparations, sauf celles qui sont urgentes!</p>
<p><strong>Envoyez une mise en demeure pour forcer le vendeur à remédier au défaut
</strong>
<strong>Consultez un avocat sans tarder</strong></p>
<h2>Votre nouvelle maison possède un défaut de garantie? Consultez un avocat sans tarder!</h2>
<p><StaticImage alt="avocat annulation vente maison garantie" src="../images/avocat-annulation-vente-maison-garantie-scaled.jpg" placeholder="blurred" imgStyle={{ maxWidth: '80%', margin: '0 auto' }}></StaticImage></p>
<p>L’achat d’une maison risque fort de représenter le plus grand investissement de votre vie. Il est donc d’autant plus important de ne pas laisser un vice causé par un propriétaire précédent (le vendeur) compromettre sa pérennité. Qu’il s’agisse d’une atteinte à votre droit de droit de propriété ou d’une malfaçon, la valeur de votre maison peut voir sa valeur diminuer substantiellement pas sa faute.</p>
<p>Ne laissez pas votre propriété à un tel sort, consultez plutôt un avocat spécialisé en droit immobilier ou en recours contre les vices cachés! Cela vous permettra d’explorer toutes les options qui vous sont offertes afin d’obtenir une juste restitution pour les troubles subis.</p>
<p><strong>Curieux d’explorer vos recours? Soumissions Avocat vous offre donc une solution fort simple! En effet, tout ce que vous avez à faire, c’est de remplir notre formulaire en bas de page et vous serez mis en contact gratuitement en contact avec un avocat qualifié dans votre région!</strong></p>
<p><strong>Allez-y, cette démarche est gratuite, sans engagement, et vous permet de parler aux avocats qualifiés dans le domaine de droit qui vous concerne!</strong></p>
    </SeoPage>
)
export default BlogPost17
  